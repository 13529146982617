<template>
  <div class="location container">

    <!-- ------------------------------- Modal: Add/Modify Locations ------------------------------------------ -->

	<CModal alignment="center" title="Location Details" color="info" :show.sync="locationModal" size="lg">	
		<div slot="header">
			<h5 v-if="location.id == ''">New Location</h5>
			<h5 v-else>Location: <b>{{location.name}}</b></h5>
		</div>	  

		<div slot="footer">
			<CButton color="dark" class="mr-3" v-on:click.prevent="serverCall('addUpdateLocation')">Save</CButton>
			<CButton color="dark" class="mr-3" v-on:click.prevent="locationModal=false">Close</CButton>
		</div>	

		<form>
			<div v-if="location.id == ''" class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Name</label>
				<input type="text" class="col-sm-6 form-control" v-model="location.name">
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Order/Rank</label>
				<input type="number" class="col-sm-3 form-control" v-model.number="location.order">

				<label class="col-sm-2 col-form-label font-weight-bold text-right">Active?</label>
				<div class="col-sm-3 text-left">
					<select class="form-control" v-model="location.activeFlag">
						<option value="true">True</option>
						<option value="false">False</option>
					</select>
				</div>
			</div>

			<div class="form-group row">
				<label class="col-sm-3 col-form-label font-weight-bold text-right">Include in Value Summary?</label>
				<div class="col-sm-3 text-left">
					<select class="form-control" v-model="location.includeInValueSummary">
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">CashSale?</label>
				<div class="col-sm-3 text-left">
					<select class="form-control" v-model="location.cashSale">
						<option value="true">True</option>
						<option value="false">False</option>
					</select>
				</div>
				<label class="col-sm-2 col-form-label font-weight-bold text-right">In Equal Out?</label>
				<div class="col-sm-3 text-left">
					<select class="form-control" v-model="location.in_equal_out">
						<option value="true">True</option>
						<option value="false">False</option>
					</select>
				</div>
			</div>

			<p class="text-danger" align="left">{{locationAlert}}</p>
		</form>

	</CModal>

    <!-- ------------------------------- Modal: Add/Modify item1s ------------------------------------------ -->

	<CModal title="Item1 Details" color="info" :show.sync="item1Modal" size="lg">	
		<div slot="header">
			<h5><b>Location:</b> {{ location.name }}, <b>Item</b>: {{ item1.name }}</h5>
		</div>	  
		<div slot="footer">
			<CButton color="dark" class="mr-3" v-on:click.prevent="addUpdateItem1()">Save</CButton>
			<CButton color="dark" class="mr-3" v-on:click.prevent="item1Modal=false">Close</CButton>
			<div>
				<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
				<scale-loader :loading="loading"></scale-loader>
			</div>
			<p class="text-danger" align="left">{{alert}}</p>
		</div>

		<form>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Name</label>
				<div v-if="existing == true">
					<label class="col-sm-3 col-form-label font-weight-bold text-right">{{item1.name}}</label>
				</div>
				<div v-else>
					<div class="col-sm-25 text-left">
						<select class="form-control" v-model="item1.name">
							<option v-for="orgItem1 in orgItem1sUnAssigned" :value="orgItem1">
								{{ orgItem1 }}
							</option>
						</select>
					</div>
				</div>

				<label class="col-sm-2 col-form-label font-weight-bold text-right">Order/Rank</label>
				<input type="number" class="col-sm-3 form-control" v-model.number="item1.order">
			</div>

			<h5 class="text-muted text-center">Sales Report</h5>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">In/Out</label>
				<div class="col-sm-3 text-left">
					<select class="form-control" v-model="item1.sales_in_out">
						<option value="in">In</option>
						<option value="out">Out</option>
						<option value="NA">N/A</option>
					</select>
				</div>

				<label class="col-sm-2 col-form-label font-weight-bold text-right">Add/Sub</label>
				<div class="col-sm-3 text-left">
					<select class="form-control" v-model="item1.sales_add_sub">
						<option value="add">Add</option>
						<option value="sub">Sub</option>
						<option value="NA">N/A</option>
					</select>
				</div>
			</div>

			<h5 class="text-muted text-center">Manufacturing Report</h5>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">In/Out</label>
				<div class="col-sm-3 text-left">
					<select class="form-control" v-model="item1.mfg_in_out">
						<option value="in">In</option>
						<option value="out">Out</option>
						<option value="NA">N/A</option>
					</select>
				</div>

				<label class="col-sm-2 col-form-label font-weight-bold text-right">Add/Sub</label>
				<div class="col-sm-3 text-left">
					<select class="form-control" v-model="item1.mfg_add_sub">
						<option value="add">Add</option>
						<option value="sub">Sub</option>
						<option value="NA">N/A</option>
					</select>
				</div>
			</div>

			<p class="text-danger" align="left">{{item1Alert}}</p>
		</form>

	</CModal>
    
    <!-- ---------------------------------------  Location/Item1 List -------------------------------------------------- -->

	<div>
		<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
		<scale-loader :loading="loading"></scale-loader>
	</div>
	<p class="text-danger" align="left">{{alert}}</p>

	<div v-if="currentScreen == 'Locations'">
		<p class="font-weight-bold" align="center">Manage Locations</p>
		<vue-good-table ref="locationTable" 
								:columns="colLocations" 
								:rows="rowLocations" 
								@on-row-click="rowChanged1" 
								:selectOptions="{
										enabled: true,
										selectOnCheckboxOnly: false, 
								}">
				<div slot="table-actions">
					<CButton color="dark" class="mr-3" v-on:click.prevent="openNewLocationDetails()">New</CButton>
				</div>
				<div slot="selected-row-actions">
					<CButton color="dark" v-on:click.prevent="openExistingLocationDetails()">Details</CButton>
					<CButton color="dark" class="ml-2" v-on:click.prevent="serverCall('deleteLocation')">Delete</CButton>
					<CButton color="dark" class="ml-2 mr-2" v-on:click.prevent="currentScreen='Item1s'">Items</CButton>
				</div>
		</vue-good-table>
	</div>
	<div v-else>
		<p class="font-weight-bold" align="center">Manage Items in Location: {{ location.name }}</p>
		<vue-good-table ref="item1Table" 
								:columns="colItem1s" 
								:rows="location['items1']" 
								@on-row-click="rowChanged2" 
								:selectOptions="{
										enabled: true,
										selectOnCheckboxOnly: false, 
								}"
								:sort-options="{
    								enabled: false,
									initialSortBy: {field: 'order', type: 'asc'}
								}">
				<div slot="table-actions">
					<CButton color="dark" v-on:click.prevent="openNewItem1Details()">Add</CButton>
					<CButton color="dark" class="ml-2 mr-2" v-on:click.prevent="currentScreen='Locations'">Locations</CButton>
				</div>
				<div slot="selected-row-actions">
					<CButton color="dark" v-on:click.prevent="openExistingItem1Details()">Details</CButton>
					<CButton color="dark" class="ml-2 mr-2" v-on:click.prevent="removeItem1()">Remove</CButton>
				</div>
		</vue-good-table>
	</div>

  </div>
</template>

<script>
import axios from "axios"
import Datepicker from 'vuejs-datepicker'
import ScaleLoader from "@/views/utils/ScaleLoader"
import { firebaseAuth } from '../../firebaseConfig.js';
import Helper from "../utils/Helper.vue"

export default {
	name: 'location',

	mixins: [
		Helper
	],

    components: {
		Datepicker,
        ScaleLoader,
	},
		
	data () {
		return {
			orgId: null,
			alert: '',
            loading: false,
            loadingMsg: null,

			currentScreen: 'Locations',

			// ************* Location ************
			locationAlert: '',
			locationModal: false,

			locations: [],
			location: {},

			colLocations: [
					{
							label: 'Name',
							field: 'name',
							width: '200px',
							html: false,
							filterOptions: {enabled: false,},
					},
					{
							label: 'Order',
							field: 'order',
							width: '100px',
							html: false,
							filterOptions: {enabled: false,},
					},
					{
							label: 'Include in Value Summary',
							field: 'includeInValueSummary',
							html: false,
							filterOptions: {enabled: false,},
					},
			],

			rowLocations: [],
			rowLocationsHtml: '',

			curRowSelected1: null,

			// ************* Item1 ************
			item1Alert: '',
			item1Modal: false,

			item1s: [],
			item1: {},

			orgItem1s: [],
			orgItem1sUnAssigned: [],

			existing: true,

			colItem1s: [
					{
							label: 'Name',
							field: 'name',
					},
					{
							label: 'Order',
							field: 'order',
					},
					{
							label: 'Sales: In/Out',
							field: 'sales_in_out',
					},
					{
							label: 'Sales: Add/Sub',
							field: 'sales_add_sub',
					},
					{
							label: 'Mfg: In/Out',
							field: 'mfg_in_out',
					},
					{
							label: 'Mfg: Add/Sub',
							field: 'mfg_add_sub',
					},
			],

			rowItem1s: [],
			rowItem1Html: '',

			curRowSelected2: null,

			// *************************

            pagination_options: {
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'top',
                perPageDropdown: [10, 20, 30, 40],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
			},
		}
	},

	computed: {
	},

	mounted(){
		this.orgId = this.$route.params['orgId']
		// todate = current date
		this.todate = new Date()

		this.serverCall('getLocations')
	},


	methods: {

        serverCall(name) {
            var self = this

			firebaseAuth.onAuthStateChanged(user => {
				if (user) {
					user.getIdToken(true).then(idToken => {
						switch(name) {
							case "getLocations":
								self.getLocations(idToken)
								break;

							case "addUpdateLocation":
								self.addUpdateLocation(idToken)
								break;

							case "deleteLocation":
								self.deleteLocation(idToken)
								break;

							default:
								console.log("Unknown function: " + name)
						}
					})
				}
				else {
					self.alert = "Please login first."
				}
			})
		},

		// ************* Location ************

        // Fetch locations for the given org
        getLocations(authToken) {
            var self = this
			self.alert = null

			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching Locations..."

            var formData = new FormData()

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetMaintLocations/',
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'multipart/form-data',
					},
					data: formData,
				})
				.then(function (response) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					
					if (response.data.err != ''){
						self.alert = response.data.err
					}
					else {
						self.rowLocations = response.data.locationList
						self.orgItem1s = response.data.item1List
					}
				})
				.catch(function (error) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					
					self.alert = String(error)
				})

		},

		// Save location changes - for new as well as existing location
        addUpdateLocation(authToken) {
            var self = this
            this.locationAlert = ""

            if (!self.location.name) {
                self.locationAlert= 'Location name not specified.'
                return
			}
            if (!self.location.order) {
                self.locationAlert= 'Location order not specified.'
                return
			}

			// Set loading message
			self.loading = true
			self.loadingMsg = "Adding/Updating Location..."

			var formData = new FormData()
			formData.append('existing', this.existing)
			formData.append('location', JSON.stringify(self.location))

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/AddUpdateLocation/',
					data: formData,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(function (response) {
					if (response.data.err != '') {
						self.locationAlert = response.data.err
						self.item1Alert = response.data.err
					}
					else {
						// Refresh item list on the screen
						self.getLocations(authToken)						
						self.locationModal = false
						self.item1Modal = false
					}
					// Reset loading message
					self.loading = false
					self.loadingMsg = null

				})
				.catch(function (error) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					self.locationAlert = String(error)
				})

		},

		// Delete Location
		deleteLocation(authToken){
            var self = this
			self.alert = null
			self.locationAlert = null

            // Check if getLocations is selected
			if (self.curRowSelected1==null){
				self.alert = "Location not selected."
				return
			}
			else{
				self.location = self.curRowSelected1
			}

			this.$confirm(self.location.name + " - will be deleted. Are you sure?").then(() => {
					// Set loading message
					self.loading = true
					self.loadingMsg = "Deleting Location..."

					var formData = new FormData()
					formData.append('locationDocId', self.location.id)

					axios({
							method: 'POST',
							baseURL: process.env.VUE_APP_ROOT_API,
							url: '/DeleteLocation/',
							data: formData,
							headers: {
								'Authorization': 'Bearer ' + authToken,
								'OrgId': self.orgId,
								'Content-Type': 'multipart/form-data'
							}
						})
						.then(function (response) {
							if (response.data.err != ''){
								self.alert = response.data.err
							}
							else {
								// Refresh item list on the screen
								self.getLocations(authToken)
							}	
							// Reset loading message
							self.loading = false
							self.loadingMsg = null				
						})
						.catch(function (error) {
							// Reset loading message
							self.loading = false
							self.loadingMsg = null
							self.locationAlert = String(error)
						})
			})

		},

		// ******************** Locations ***************

		rowChanged1(params){	
			// Current selected Row
			if (this.curRowSelected1 != null){
				// If new row is not same as previous row, uncheck previous row
				if (this.curRowSelected1.originalIndex!=params.row.originalIndex)
					this.curRowSelected1.vgtSelected = false
			}
			// New selected row
			this.curRowSelected1 = params.row
			this.location= this.curRowSelected1
		},

		// Open details for existing location
		openExistingLocationDetails(){
            var self = this
			self.alert = null
			self.locationAlert = null

            // Check if location is selected
			if (self.curRowSelected1==null){
				self.alert = "Location not selected."
				return
			}

			self.location= self.curRowSelected1

			self.locationModal = true
		},

		// Open details for new item1
		openNewLocationDetails(){
			this.alert = null
			this.locationAlert = null

			// Initialize location object
			this.location = {
				name: '',
				order: 0,
				includeInValueSummary: 'Yes',
				id: '',
				items1: [],
				activeFlag: true,
				cashSale: true,
				in_equal_out: false,
			}

			this.locationModal = true
		},

		// ************* Item1s ************
		
		rowChanged2(params){	
			// Current selected Row
			if (this.curRowSelected2 != null){
				// If new row is not same as previous row, uncheck previous row
				if (this.curRowSelected2.originalIndex!=params.row.originalIndex)
					this.curRowSelected2.vgtSelected = false
			}
			// New selected row
			this.curRowSelected2 = params.row
			this.item1= this.curRowSelected2
		},

		// Open details for existing item1
		openExistingItem1Details(){
            var self = this
			self.alert = null
			self.item1Alert = null

            // Check if item1 is selected
			if (self.curRowSelected2==null){
				self.alert = "Item1 not selected."
				return
			}

			self.existing = true

			self.item1 = self.curRowSelected2

			self.item1Modal = true
		},

		// Open details for new item1
		openNewItem1Details(){
			this.alert = null
			this.item1Alert = null
			this.curRowSelected2==null

			// Initialize item1 object
			this.item1 = {
				name: '',
				order: 0,
				sales_in_out: 'out',
				sales_add_sub: 'add',
				mfg_in_out: 'out',
				mfg_add_sub: 'add',
				vgtSelected: false
			}

			this.existing = false

			// Filter out item1s already added to report: orgItem1List, item1s
			this.orgItem1sUnAssigned = this.orgItem1s
			for (const item of this.location['items1']){
				this.orgItem1sUnAssigned = this.removeArrayElement(this.orgItem1sUnAssigned, item['name'])
			}

			if (this.orgItem1sUnAssigned.length == 0){
				this.alert = "All items are alredy assigned."
			}
			else {
				this.item1Modal = true
			}
		},

		// Save item1 changes - for new as well as existing item1
        addUpdateItem1() {
            var self = this
            this.item1Alert = ""

            if (!self.item1.name) {
                self.item1Alert= 'Item name not specified.'
                return
			}
            if (!self.item1.order) {
                self.item1Alert= 'Item order not specified.'
                return
			}
			self.item1.order = parseInt(self.item1.order)
			self.item1['vgtSelected'] = false

			// Update of existing item1
			if (self.curRowSelected2!=null){
				var item1row = self.curRowSelected2['originalIndex']
				self.location['items1'][item1row] = self.item1
			}
			// Add new item1
			else {
				self.location['items1'].push(self.item1)
				self.rowItem1s.push(self.item1)
			}

			self.serverCall('addUpdateLocation')

		},

		// Remove Item1
		removeItem1(){
            var self = this
			self.alert = null
			self.item1Alert = null

            // Check if item1 is selected
			if (self.curRowSelected2==null){
				self.alert = "Item not selected."
				return
			}

			this.$confirm(self.item1.name + " - will be removed from this location. Are you sure?").then(() => {
				var item1row = self.curRowSelected2['originalIndex']
				self.location['items1'].splice(item1row, 1)
				self.serverCall('addUpdateLocation')
			})
		},

	},

}
</script>

<style>

</style>

